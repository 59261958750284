import React, {Component, useEffect, useState} from "react";
import { Link } from "react-router-dom";
import ImageList from '@material-ui/core/ImageList';
import { ImageListItem } from '@material-ui/core';
import { LinearProgress } from '@material-ui/core';
import { Button } from '@material-ui/core';
import Header from "../Header";
import "./styles.css";
import img from "./static/20190128_182550.jpg"

// const importAll = async () => {

  
//   console.log(data_images);
// }

var data_images = {}

const Expire = props => {
  
  const setVisible = () => {
    console.log("lets see ya")
    let d = document.getElementById("invis");
    d.style.display = "block"
    let load = document.getElementById("loading");
    load.style.display = "none"
  }

  useEffect(() => {
    setTimeout(() => {
      setVisible();
    }, props.delay);
  }, [props.delay]);

  return (
    <>
      <div id="loading">
        <br/>
        <br/>
        <LinearProgress />
      </div>
      <div id="invis" style={{"display":"none"}}>
        {props.children}
      </div>
    </>
  );
};

/* Component for the Home page */
class Travel extends Component {

  constructor() {
      super();
      this.state = {
        photos: {},
      }
  };

  componentDidMount() {
    this.fetchPhotosAsync();
  }

  async fetchPhotosAsync() {
    try{
        const response = await fetch("https://imgur-apiv3.p.rapidapi.com/3/album/tlJbpiR/images", {
        "method": "GET",
        "headers": {
          "authorization": "Bearer 893249c8df009ae37f8b42ec7253218e69a0139f",
          "x-rapidapi-key": "5f8c26f5f1mshf31eb2868c7b175p1c036bjsnea7c01b2bf7f",
          "x-rapidapi-host": "imgur-apiv3.p.rapidapi.com"
        }
      })

      const result = await response.json();
      const photos = await result.data;
      console.log(photos)
      this.setState({photos})

    } catch(e) {
      console.log(e);
    }

  }

  render() {

    return (
      <div>
        <Header/>
        <div className="background">
          <h1 className="section-title">Travel Gallery</h1>
        </div>
        <div className="quotation-div">
          <hr className="line"/>
          <blockquote className="quote">
            "There's only one difference between art and science. In science, the universe is in control.<br/><span className="effect">In art, you are."</span><br/><span className="author">- Harold Kroto, Nobel Prize in Chemistry</span>
          </blockquote>
          <hr className="line"/>
        </div>
        <div className="projects">
          <Expire delay="20000">
              <ImageList variant="masonry" cols={4} gap={8}>
                {Object.entries(this.state.photos).sort(() => Math.random() - 0.5).map(([obj, i]) => (
                  <ImageListItem key={this.state.photos[obj].id}>
                    <img
                      srcSet={`${this.state.photos[obj].link}?w=161&fit=crop&auto=format 1x,
                        ${this.state.photos[obj].link}?w=161&fit=crop&auto=format&dpr=2 2x`}
                      alt={this.state.photos[obj].id}
                    />
                  </ImageListItem>
                ))}
              </ImageList>
          </Expire>
        </div>    
      </div>
    );
  }
}

export default Travel;