import React from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Header from "../Header";
import "./styles.css";
import { Card, CardColumns} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ModalVideo from 'react-modal-video'
import 'react-modal-video/scss/modal-video.scss';

/* Component for the Home page */
class Projects extends React.Component {
  constructor () {
    super()
    this.state = {
      isOpen: false,
      videoID: null
    }
    this.openModal = this.openModal.bind(this)
  }

  openModal (videoid) {
    this.setState({
      isOpen: !this.state.isOpen,
      videoID: videoid
    })
  }

  render() {

    const mystyle = {
      marginLeft: '5%',
      border: '1px solid #cfa197'
    };

    return (
      <div>
        <Header/>
        <div className="background">
          <h1 className="section-title">Projects</h1>
        </div>
        <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId={this.state.videoID} onClose={() => this.setState({isOpen: false})} />
        <div className="projects">
          <CardColumns>
            <Card border="secondary" className="text-center">
              <Card.Body>
                <Card.Title>The Daily Bit</Card.Title>
                <Card.Text>
                  A website, employing React and Express Node JS, intended for users to streamline their news content pertaining to social and global injustices occuring around the world (ex. COVID-19, Yemen Crisis, etc).
                </Card.Text>
                <Button style={{backgroundColor: '#cfa197'}} variant="primary" onClick={() => this.openModal("Izh-AyoxzAs")} >Watch Demo</Button>
                <Button style={mystyle} variant="primary" onClick={() => window.open("https://github.com/radhika-lakhtakia/2bitteam", '_blank')} >View Code</Button>
              </Card.Body>
            </Card>
            <Card style={{backgroundColor: '#e8dbd8', color:"#808080"}} className="text-center p-3">
              <blockquote className="blockquote mb-0 card-body">
                <p>
                  “We need to understand that if we all work on inclusion together, it’s going to be faster, broader, better, and more thorough than anything we can do on our own.”

                </p>
                <footer style={{color: '#808080'}} className="blockquote-footer">
                  <small className="text-muted">
                  <cite style={{color: '#808080'}} title="Source Title">Ellen Pao</cite>
                  </small>
                </footer>
              </blockquote>
            </Card>
            <Card border="secondary" className="text-center">
              <Card.Body>
                <Card.Title>Restaurant Manager</Card.Title>
                <Card.Text>
                  A web-based application, utilizing Express Node JS, intended for restaurant owners to better manage their finance, francise, inventory, and employee data.
                </Card.Text>
                <Button style={mystyle} variant="primary" onClick={() => window.open("https://github.com/radhika-lakhtakia/team31", '_blank')} >View Code</Button>
              </Card.Body>
            </Card>
            <Card style={{backgroundColor: '#e8dfd8', color:"#808080"}} className="text-center p-3">
              <blockquote className="blockquote mb-0 card-body">
                <p>
                  I love technology, and I don’t think it’s something that should divide along gender lines.
                </p>
                <footer style={{color: '#808080'}} className="blockquote-footer">
                  <small className="text-muted">
                    <cite style={{color: '#808080'}} title="Source Title">Marissa Mayer</cite>
                  </small>
                </footer>
              </blockquote>
            </Card>
            <Card border="secondary" className="text-center">
              <Card.Body>
                <Card.Title>Money Mitosis</Card.Title>
                <Card.Text>
                  A blueprint of a bill splitting app designed after conducting user testing a research, designed in Figma.
                </Card.Text>
                <Button style={mystyle} variant="primary" onClick={() => window.open("https://www.figma.com/file/Cuar6Yewsa17Gof7ZB8cSEo3/MoneyMitosis-Prototype?node-id=63%3A60", '_blank')} >View Code</Button>
              </Card.Body>
            </Card>
            <Card style={{backgroundColor: '#dcbeb7'}} text="white" className="text-center p-3">
              <blockquote className="blockquote mb-0 card-body">
                <p>
                  “I would like to be remembered as someone who was not afraid to do what she wanted to do, and as someone who took risks along the way in order to achieve her goals.”
                </p>
                <footer style={{color: 'white'}} className="blockquote-footer">
                  <small className="text-muted">
                    <cite style={{color: 'white'}} title="Source Title">Sally Ride</cite>
                  </small>
                </footer>
              </blockquote>
            </Card>
            <Card border="secondary" className="text-center">
              <Card.Body>
                <Card.Title>Income Predictor</Card.Title>
                <Card.Text>
                  A simple web-based application leveraging IBM Cloud and Watson AI, intended to predict upcoming average household income of a specified area on a map. 
                </Card.Text>
                <Button style={mystyle} variant="primary" onClick={() => window.open("https://github.com/radhika-lakhtakia/house-predictor", '_blank')} >View Code</Button>
              
              </Card.Body>
            </Card>
              <Card style={{backgroundColor: '#dcc7b7'}} text="white" className="text-center p-3">
              <blockquote className="blockquote mb-0 card-body">
                <p>
                  “The best way to predict the future is to implement it.”
                </p>
                <footer style={{color: 'white'}} className="blockquote-footer">
                  <small className="text-muted">
                    <cite style={{color: 'white'}} title="Source Title">David Heinemeier Hansson</cite>
                  </small>
                </footer>
              </blockquote>
            </Card>
            <Card border="secondary" className="text-center">
              <Card.Body>
                <Card.Title>Giastros Book</Card.Title>
                <Card.Text>
                  A GUI utilizing Java and Java Fx to make a command line interface to manage patient files for a hospital. 
                </Card.Text>
                <Button style={mystyle} variant="primary" onClick={() => window.open("https://github.com/radhika-lakhtakia/addressbook-level4", '_blank')} >View Code</Button>

              
              </Card.Body>
            </Card>  
              <Card style={{backgroundColor: '#dcb7bb'}} text="white" className="text-center p-3">
              <blockquote className="blockquote mb-0 card-body">
                <p>
                  “Life is a series of building, testing, changing and iterating.”
                </p>
                <footer style={{color: 'white'}} className="blockquote-footer">
                  <small className="text-muted">
                    <cite style={{color: 'white'}} title="Source Title">Lauren Mosenthal</cite>
                  </small>
                </footer>
              </blockquote>
            </Card> 
            <Card border="secondary" className="text-center">
              <Card.Body>
                <Card.Title>MS Paint Clone</Card.Title>
                <Card.Text>
                  A GUI utilizing Java and Java Swing to make a replica Microsoft Paint product in which users can draw various objects and save their work. 
                </Card.Text>
                <Button style={mystyle} variant="primary" onClick={() => window.open("https://github.com/radhika-lakhtakia/paint-clone/", '_blank')} >View Code</Button>
              
              </Card.Body>
            </Card>
              <Card style={{backgroundColor: '#e8dfd8', color:"#808080"}} className="text-center p-3">
              <blockquote className="blockquote mb-0 card-body">
                <p>
                  “Nothing in life is to be feared, it is only to be understood. Now is the time to understand more, so that we may fear less.”
                </p>
                <footer style={{color: '#808080'}} className="blockquote-footer">
                  <small className="text-muted">
                    <cite style={{color: '#808080'}} title="Source Title">Marie Curie</cite>
                  </small>
                </footer>
              </blockquote>
            </Card>
            <Card border="secondary" className="text-center">
              <Card.Body>
                <Card.Title>Client Server Cluster</Card.Title>
                <Card.Text>
                  Leveraged sockets in C to implement an active network protocol for a computer cluster 
                </Card.Text>
                <Button style={mystyle} variant="primary" onClick={() => window.open("https://github.com/radhika-lakhtakia/client-server-cluster", '_blank')} >View Code</Button>

              
              </Card.Body>
            </Card>
              <Card style={{backgroundColor: '#e8dbd8', color:"#808080"}} className="text-center p-3">
              <blockquote className="blockquote mb-0 card-body">
                <p>
                  “It’s OK to figure out murder mysteries, but you shouldn’t need to figure out code.  You should be able to read it.”
                </p>
                <footer style={{color: "#808080"}} className="blockquote-footer">
                  <small className="text-muted">
                    <cite style={{color: "##808080"}} title="Source Title">Steve McConnell</cite>
                  </small>
                </footer>
              </blockquote>
            </Card>
            <Card border="secondary" className="text-center">
              <Card.Body>
                <Card.Title>Water Jugs</Card.Title>
                <Card.Text>
                  An MVC implementation availing Java to gameify the classic riddle - given 3 jugs of different sizes, how can you manipulate the jugs to fill one jug with 4 liters of water.
                </Card.Text>
                <Button style={mystyle} variant="primary" onClick={() => window.open("https://github.com/radhika-lakhtakia/water_jugs", '_blank')} >View Code</Button>
              
              </Card.Body>
            </Card>
            
          </CardColumns>
        </div>
    </div>
    );
  }
}

export default Projects;
