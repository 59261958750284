import React from "react";
import "./styles.css";
import { LinkContainer} from "react-router-bootstrap";

/* The Header Component */
class Header extends React.Component {
  constructor() {
      super();
      this.state = {
          toggleMenu: false
      }
      this.handleToggle = this.handleToggle.bind(this);
    }

  handleToggle(){
    this.setState({toggleMenu: !this.state.toggleMenu});
  }

  render() {
    return (
      <>
        <div className="header">
          <LinkContainer to='/' className='home______button'>
            <a >HOME</a>
          </LinkContainer>
          <LinkContainer to='/About-Me' className='button'>
            <a >ABOUT ME</a>
          </LinkContainer>
          <LinkContainer to='/Projects' className='button'>
            <a >PROJECTS</a>
          </LinkContainer>
          <LinkContainer to='/Contact-Me' className='button'>
            <a >CONTACT ME</a>
          </LinkContainer>
          <LinkContainer to='/Travel-Gallery' className='button'>
            <a >TRAVEL GALLERY</a>
          </LinkContainer>
        </div>
        <div className="menunav">
          <a id="menu" onClick={this.handleToggle}>Menu</a>
          {this.state.toggleMenu ?
            <>
              <LinkContainer to='/' className='home______button'>
                <a >HOME</a>
              </LinkContainer>
              <LinkContainer to='/About-Me' className='button'>
                <a >ABOUT ME</a>
              </LinkContainer>
              <LinkContainer to='/Projects' className='button'>
                <a >PROJECTS</a>
              </LinkContainer>
              <LinkContainer to='/Contact-Me' className='button'>
                <a >CONTACT ME</a>
              </LinkContainer>
              <LinkContainer to='/Travel-Gallery' className='button'>
                <a >TRAVEL GALLERY</a>
              </LinkContainer> 
            </>: ""}
        </div>
      </>
    );
  }
}

export default Header;
