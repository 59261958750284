import React from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { LinkContainer} from "react-router-bootstrap";

import "./styles.css";

/* Component for the Home page */
class Home extends React.Component {
  constructor() {
      super();
      this.state = {
          toggleMenu: false
      }
      this.handleToggle = this.handleToggle.bind(this);
    }

  handleToggle(){
    this.setState({toggleMenu: !this.state.toggleMenu});
  }

  render() {
    return (
    <div>
      <div className="bg-image center">
        <div className="hero-text">
          <br/>
          <div className="main-menu">
            <LinkContainer to='/About-Me' className='__button'>
              <Button className="__button">About Me</Button>
            </LinkContainer>
            <LinkContainer to='/Projects' className='__button'>
              <Button className="__button">Projects</Button>
            </LinkContainer>
            <LinkContainer to='/Contact-Me' className='__button'>
              <Button className="__button">Contact Me</Button>
            </LinkContainer>
            <LinkContainer to='/Travel-Gallery' className='__button'>
              <Button className="__button">Travel Gallery</Button>
            </LinkContainer>
          </div>
          <br/>
		</div>
    <div className="menunavi">
      <a id="menuu" onClick={this.handleToggle}>Menu</a>
      {this.state.toggleMenu ?
        <div className="menubar">
          <LinkContainer to='/About-Me' className='button'>
            <a >ABOUT ME</a>
          </LinkContainer>
          <LinkContainer to='/Projects' className='button'>
            <a >PROJECTS</a>
          </LinkContainer>
          <LinkContainer to='/Contact-Me' className='button'>
            <a >CONTACT ME</a>
          </LinkContainer>
          <LinkContainer to='/Travel-Gallery' className='button'>
            <a >TRAVEL GALLERY</a>
          </LinkContainer> 
        </div>: ""}
    </div>
		<div className="hero-text">
		  <h1 className="title">Hi! I'm Radhika Lakhtakia</h1>
		  <p className="subtitle">And I'm a Software Developer!</p>
		</div>
      </div>
    </div>
    );
  }
}

export default Home;